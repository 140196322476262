import PdfUtils from "./pdfUtils";
import autoTable from 'jspdf-autotable';
const footerImg = require("@/assets/images/footerReport.png");
const logo = require("@/assets/images/logoRed.png");

function header(pdf) {
    pdf.setFontSize(10);
    pdf.addImage(logo, "PNG", 2, 2, 57, 30);

    let Y = 5;
    pdf.drawText("RELATÓRIO GERENCIAL DE SERVIÇOS", 136, Y, { font: 'bold' });
    pdf.printNormalAndBold("**Sollus** Indústria Eletrônica Ltda.", 60, Y);

    Y += 5;
    pdf.drawText("CNPJ/MF: 22.108.794/0001-44", 60, Y);
    Y += 10;
    pdf.drawText("SAC: +55 (35) 3473-0824", 60, Y);
}

function printCondominium(pdf, condominium, x, y) {
    if (condominium) {
        if (condominium.name) {
            pdf.printNormalAndBold(`**Nome:** ${condominium.name}`, x, y);
        }
        if (condominium.cnpj) {
            y += 5;
            pdf.printNormalAndBold(`**CNPJ:** ${condominium.cnpj}`, x, y);
        }
        if (condominium.address) {
            y += 5;
            pdf.printNormalAndBold(`**Endereço:** ${condominium.address}`, x, y);
        }
        if (condominium.cep) {
            y += 5;
            pdf.printNormalAndBold(`**CEP:** ${condominium.cep}`, x, y);
        }
        if (condominium.city) {
            y += 5;
            pdf.printNormalAndBold(`**Cidade / UF:** ${condominium.city}`, x, y);
        }
    }
}

function footer(pdf) {
    const doc = pdf.getDoc();
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    pdf.addImage(footerImg, "PNG", 0, pageHeight - 125, pageWidth, 125);
}

function printPage(pdf, items, price, startY) {
    let cols = [
        { header: 'Bloco', dataKey: 'block' },
        { header: 'Apartamento', dataKey: 'apartment' },
        { header: 'Consumo', dataKey: 'consumption' },
    ];
    if (price > 0) {
        cols.push({ header: 'Preço', dataKey: 'price' });
    }

    autoTable(pdf.getDoc(), {
        theme: 'grid',
        margin: { left: 5, right: 5 },
        headStyles: { fillColor: [255, 88, 91], textColor: [255, 255, 255], fontStyle: 'bold', lineWidth: 0.5, lineColor: [255, 255, 255], halign: 'center' },
        bodyStyles: { fillColor: [255, 88, 91], textColor: [255, 255, 255], fontStyle: 'normal', lineWidth: 0.5, lineColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [255, 88, 91], textColor: [255, 255, 255], fontStyle: 'normal', lineWidth: 0.5, lineColor: [255, 255, 255] },
        startY: startY,
        columns: cols,
        body: items,
    });
}

export default {
    createCondominiumReport: (condominium, printRange, printInterval, items, total, price) => {
        const pdf = new PdfUtils();

        let itemsProcessed = JSON.parse(JSON.stringify(items));
        itemsProcessed.map(i => {
            i.consumption = `${i.consumption} kWh`;
            if (price) {
                i.price = `R$ ${Number.parseFloat(Number.parseFloat(i.consumption) * Number.parseFloat(price)).toFixed(2)}`;
            }
            return i;
        });

        header(pdf);

        let startY1 = 50, startY2 = 100, startX1 = 5, startX2 = 40;
        if (itemsProcessed.length <= 10) {
            startY1 = 38;
            startY2 = 38;
            startX1 = 5;
            startX2 = 85;
        }
        printCondominium(pdf, condominium, startX1, startY1);

        if (printRange) {
            pdf.drawBox({ title: printRange, subtitle: "Periodo", x: startX2, y: startY2 });
        }
        if (printInterval) {
            pdf.drawBox({ title: printInterval, subtitle: "Total de dias", x: startX2, y: startY2 + 16 });
        }
        if (total) {
            pdf.drawBox({ title: `${total} kWh`, subtitle: "Energia consumida", x: startX2 + 60, y: startY2 });
        }
        if (total && price > 0) {
            pdf.drawBox({ title: `R$ ${Number.parseFloat(total * price).toFixed(2)}`, subtitle: "Valor Total", x: startX2 + 60, y: startY2 + 16 });
        }

        footer(pdf);

        const totalPages = itemsProcessed.length <= 10 ? 1 : Math.ceil(itemsProcessed.length / 15) + 1;
        pdf.getDoc().setPage(1);
        pdf.drawText(`Página 1 de ${totalPages}`, 136, 20, { font: 'bold' });

        if (itemsProcessed.length <= 10) {
            printPage(pdf, itemsProcessed, price, 80);
        } else {
            const itemsPerPage = 15;
            let currentPageItems = [];
            for (let i = 0; i < itemsProcessed.length; i++) {
                currentPageItems.push(itemsProcessed[i]);
                if (currentPageItems.length === itemsPerPage || i === itemsProcessed.length - 1) {
                    if (pdf.getDoc().internal.getNumberOfPages() > 0) {
                        pdf.getDoc().addPage();
                    }
                    header(pdf);
                    printPage(pdf, currentPageItems, price, 40);
                    footer(pdf);
                    currentPageItems = [];
                    const currentPage = pdf.getDoc().internal.getNumberOfPages();
                    pdf.drawText(`Página ${currentPage} de ${totalPages}`, 136, 20, { font: 'bold' });
                }
            }
        }

        return pdf.getDoc();
    },
};